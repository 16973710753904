const Pmenu = [
    // {
    //   "image": "http://printingbrother.com/images/cs.jpg",
    //   "id": 1,
    //   // "url": "./component/Internal_Pages/Tshirt",
    //   "title": "Cold Cups",
    //   "alt": "Cold Cups",
    //   "discription": "Discover the perfect gift for any occasion on our gifting website, where we offer a versatile range of T-shirts crafted from high-quality materials including Polyester, Poly cotton, and Cotton. Whether you're seeking comfort, durability, or style, our selection has something for everyone."
    // },
    {
      "image": "http://printingbrother.com/images/wristband_printingbrothers.jpg",
      "id": 1,
      // "url": "./component/Internal_Pages/Tshirt",
      "title": "Wristband",
      "alt": "Wristband",
      "discription": "Discover the perfect gift for any occasion on our gifting website, where we offer a versatile range of T-shirts crafted from high-quality materials including Polyester, Poly cotton, and Cotton. Whether you're seeking comfort, durability, or style, our selection has something for everyone."
    },
    {
      "image": "http://printingbrother.com/images/caryBag_printingbrothers.jpg",
      "id": 1,
      // "url": "./component/Internal_Pages/Tshirt",
      "title": "Cary Bag",
      "alt": "Cary Bag",
      "discription": "Discover the perfect gift for any occasion on our gifting website, where we offer a versatile range of T-shirts crafted from high-quality materials including Polyester, Poly cotton, and Cotton. Whether you're seeking comfort, durability, or style, our selection has something for everyone."
    },
    {
      "image": "http://printingbrother.com/images/cuecards_printingbrothers.jpg",
      "id": 1,
      // "url": "./component/Internal_Pages/Tshirt",
      "title": "Cue card",
      "alt": "Cue card",
      "discription": "Discover the perfect gift for any occasion on our gifting website, where we offer a versatile range of T-shirts crafted from high-quality materials including Polyester, Poly cotton, and Cotton. Whether you're seeking comfort, durability, or style, our selection has something for everyone."
    },
    {
      "image": "http://printingbrother.com/images/envelope_printingbrothers.jpg",
      "id": 1,
      // "url": "./component/Internal_Pages/Tshirt",
      "title": "Envelope",
      "alt": "Envelope",
      "discription": "Discover the perfect gift for any occasion on our gifting website, where we offer a versatile range of T-shirts crafted from high-quality materials including Polyester, Poly cotton, and Cotton. Whether you're seeking comfort, durability, or style, our selection has something for everyone."
    },
    {
      "image": "http://printingbrother.com/images/leaflet_printingbrothers.jpg",
      "id": 1,
      // "url": "./component/Internal_Pages/Tshirt",
      "title": "Leaflet",
      "alt": "Leaflet",
      "discription": "Discover the perfect gift for any occasion on our gifting website, where we offer a versatile range of T-shirts crafted from high-quality materials including Polyester, Poly cotton, and Cotton. Whether you're seeking comfort, durability, or style, our selection has something for everyone."
    },
    {
      "image": "http://printingbrother.com/images/tentcard_printingbrothers.jpg",
      "id": 1,
      // "url": "./component/Internal_Pages/Tshirt",
      "title": "Tent Card",
      "alt": "Tent Card",
      "discription": "Discover the perfect gift for any occasion on our gifting website, where we offer a versatile range of T-shirts crafted from high-quality materials including Polyester, Poly cotton, and Cotton. Whether you're seeking comfort, durability, or style, our selection has something for everyone."
    }
    
  ];
 export default Pmenu;