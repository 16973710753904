const Menu = [
    {
      "image": "http://printingbrother.com/images/6-2.webp",
      "id": 1,
      "url": "./component/Internal_Pages/Tshirt",
      "title": "T-Shirts",
      "alt": "T-Shirt",
      "discription": "Discover the perfect gift for any occasion on our gifting website, where we offer a versatile range of T-shirts crafted from high-quality materials including Polyester, Poly cotton, and Cotton. Whether you're seeking comfort, durability, or style, our selection has something for everyone."
    },
    {
      "image": "http://printingbrother.com/images/10.webp",
      "id": 2,
      "url": "./component/Internal_Pages/Hoodies",
      "title": "Sweatshirts & Hoodies",
      "alt": "Sweatshirts & Hoodies",
      "discription": "est rerum tempore vitae\nsequi sint nihil reprehenderit dolor beatae ea dolores neque\nfugiat blanditiis voluptate porro vel nihil molestiae ut reiciendis\nqui aperiam non debitis possimus qui neque nisi nulla"
    },
    {
      "image": "http://printingbrother.com/images/caps-and-hats-1.webp",
      "id": 3,
      "url": "./component/Internal_Pages/Caps",
      "title": "Caps & Hats ",
      "alt": "Caps ",
      "discription": "et iusto sed quo iure\nvoluptatem occaecati omnis eligendi aut ad\nvoluptatem doloribus vel accusantium quis pariatur\nmolestiae porro eius odio et labore et velit aut"
    },
    {
      "image": "http://printingbrother.com/images/drinkware-1.webp",
      "id": 4,
      "url": "./component/Internal_Pages/Mugs",
      "title": "Drinkware",
      "alt": "Drinkware",
      "discription": "quia et suscipit\nsuscipit recusandae consequuntur expedita et cum\nreprehenderit molestiae ut ut quas totam\nnostrum rerum est autem sunt rem eveniet architecto"
    },
    {
      "image": "http://printingbrother.com/images/img_5.jpg",
      "id": 5,
      "url": "./component/Internal_Pages/Pens",
      "title": "Diary & Pen ",
      "alt": "Diary & Pen",
      "discription": "est rerum tempore vitae\nsequi sint nihil reprehenderit dolor beatae ea dolores neque\nfugiat blanditiis voluptate porro vel nihil molestiae ut reiciendis\nqui aperiam non debitis possimus qui neque nisi nulla"
    },
    // {
    //   "image": "http://printingbrother.com/images/img_6.jpg",
    //   "id": 6,
    //   "url": "./component/Internal_Pages/Drinkware",
    //   "title": "Drinkware",
    //   "alt": "Drinkware",
    //   "discription": "est rerum tempore vitae\nsequi sint nihil reprehenderit dolor beatae ea dolores neque\nfugiat blanditiis voluptate porro vel nihil molestiae ut reiciendis\nqui aperiam non debitis possimus qui neque nisi nulla"
    // },
    {
      "image": "http://printingbrother.com/images/img_7.jpg",
      "id": 7,
      "url": "./component/Internal_Pages/Coasters",
      "title": "Coasters",
      "alt": "Coasters",
      "discription": "est rerum tempore vitae\nsequi sint nihil reprehenderit dolor beatae ea dolores neque\nfugiat blanditiis voluptate porro vel nihil molestiae ut reiciendis\nqui aperiam non debitis possimus qui neque nisi nulla"
    },
    {
      "image": "http://printingbrother.com/images/img_8.jpg",
      "id": 8,
      "url": "./component/Internal_Pages/KeyChains",
      "title": "Key Chains",
      "alt": "Key Chanins",
      "discription": "est rerum tempore vitae\nsequi sint nihil reprehenderit dolor beatae ea dolores neque\nfugiat blanditiis voluptate porro vel nihil molestiae ut reiciendis\nqui aperiam non debitis possimus qui neque nisi nulla"
    },
    {
      "image": "http://printingbrother.com/images/img_4.jpg",
      "id": 9,
      "url": "./component/Internal_Pages/Lanyards",
      "title": "Lanyard & Badges",
      "alt": "lanyards and badges",
      "discription": "est rerum tempore vitae\nsequi sint nihil reprehenderit dolor beatae ea dolores neque\nfugiat blanditiis voluptate porro vel nihil molestiae ut reiciendis\nqui aperiam non debitis possimus qui neque nisi nulla"
    },
    {
      "image": "http://printingbrother.com/images/img_10.jpg",
      "id": 10,
      "url": "./component/Internal_Pages/Bags",
      "title": "Customized Bags",
      "alt": "Customized Bags",
      "discription": "quo et expedita modi cum officia vel magni\ndoloribus qui repudiandae\nvero nisi sit\nquos veniam quod sed accusamus veritatis error"
    }
      // ,
    // {
    //   "image": "http://printingbrother.com/images/img_11.jpg",
    //   "id": 11,
    //   "url": "./component/Internal_Pages/Diaries",
    //   "title": "Diaries",
    //   "alt": "Diaries",
    //   "discription": "quo et expedita modi cum officia vel magni\ndoloribus qui repudiandae\nvero nisi sit\nquos veniam quod sed accusamus veritatis error"
    // }
   ,
   {
    "image": "http://printingbrother.com/images/wristband_printingbrothers.jpg",
    "id": 1,
    // "url": "./component/Internal_Pages/Tshirt",
    "title": "Wristband",
    "alt": "Wristband",
    "discription": "Discover the perfect gift for any occasion on our gifting website, where we offer a versatile range of T-shirts crafted from high-quality materials including Polyester, Poly cotton, and Cotton. Whether you're seeking comfort, durability, or style, our selection has something for everyone."
  },
  {
    "image": "http://printingbrother.com/images/caryBag_printingbrothers.jpg",
    "id": 1,
    // "url": "./component/Internal_Pages/Tshirt",
    "title": "Cary Bag",
    "alt": "Cary Bag",
    "discription": "Discover the perfect gift for any occasion on our gifting website, where we offer a versatile range of T-shirts crafted from high-quality materials including Polyester, Poly cotton, and Cotton. Whether you're seeking comfort, durability, or style, our selection has something for everyone."
  },
  {
    "image": "http://printingbrother.com/images/cuecards_printingbrothers.jpg",
    "id": 1,
    // "url": "./component/Internal_Pages/Tshirt",
    "title": "Cue card",
    "alt": "Cue card",
    "discription": "Discover the perfect gift for any occasion on our gifting website, where we offer a versatile range of T-shirts crafted from high-quality materials including Polyester, Poly cotton, and Cotton. Whether you're seeking comfort, durability, or style, our selection has something for everyone."
  },
  {
    "image": "http://printingbrother.com/images/envelope_printingbrothers.jpg",
    "id": 1,
    // "url": "./component/Internal_Pages/Tshirt",
    "title": "Envelope",
    "alt": "Envelope",
    "discription": "Discover the perfect gift for any occasion on our gifting website, where we offer a versatile range of T-shirts crafted from high-quality materials including Polyester, Poly cotton, and Cotton. Whether you're seeking comfort, durability, or style, our selection has something for everyone."
  },
  {
    "image": "http://printingbrother.com/images/leaflet_printingbrothers.jpg",
    "id": 1,
    // "url": "./component/Internal_Pages/Tshirt",
    "title": "Leaflet",
    "alt": "Leaflet",
    "discription": "Discover the perfect gift for any occasion on our gifting website, where we offer a versatile range of T-shirts crafted from high-quality materials including Polyester, Poly cotton, and Cotton. Whether you're seeking comfort, durability, or style, our selection has something for everyone."
  },
  {
    "image": "http://printingbrother.com/images/tentcard_printingbrothers.jpg",
    "id": 1,
    // "url": "./component/Internal_Pages/Tshirt",
    "title": "Tent Card",
    "alt": "Tent Card",
    "discription": "Discover the perfect gift for any occasion on our gifting website, where we offer a versatile range of T-shirts crafted from high-quality materials including Polyester, Poly cotton, and Cotton. Whether you're seeking comfort, durability, or style, our selection has something for everyone."
  } 
  ];
 export default Menu;